#partner{
    padding-top: 5rem;
    /* height: calc(100vh - 15rem); */
    height: auto;
    width: 100vw;
    max-width: 100rem;
    margin: 0 auto;
    /* text-align: center;
    align-items: center;
    justify-content: center; */
    color: rgb(40, 40, 40);

}

.partnerTitle{
    margin: 0 auto;
    text-align: center;
    font-family: "Allan", serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1px;
    color: rgb(16, 112, 190);
    /* color: rgb(16, 112, 190); */
    font-size: 4rem;
    margin-bottom: 2rem;
}

.partnerSmall{
    margin: 0.5rem;
    margin-bottom: 4rem;
    text-align: center;
    font-size: 1.2rem;
    line-height: 2rem;
}

.partnerBody{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 5rem;
}

.partner{
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 25rem;
    text-align: center;
    margin: 0 5rem;
}

.partnerImg{
    object-fit: cover;
    /* width: 20rem; */
    height: 10rem;
    margin-bottom: 2rem;
}

.partnerText{
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.partnerLink{
    font-size: 1.3rem;
    margin-bottom: 1rem;
    text-decoration: none;
    padding-bottom: 0rem;
    border-bottom: 3px solid rgb(30, 30, 30);
    color: rgb(30, 30, 30);
}


@media screen and (max-width:1300px) {
    .partnerBody{
        display: block;
        justify-content: center;
        width: 100%;
    }

    #partner{
        height: auto;
    }

    .partner{
        margin: auto;
        margin-bottom: 5rem;
    }
}