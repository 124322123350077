#imgandvid{
    padding-top: 5rem;
    height: auto;
    width: 100vw;
    max-width: 100rem;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    justify-content: center; 
    color: rgb(40, 40, 40);

}

.imgandvidTitle{
    margin: 0 auto;
    text-align: center;
    font-family: "Allan", serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1px;
    color: rgb(16, 112, 190);
    /* color: rgb(16, 112, 190); */
    font-size: 4rem;
    margin-bottom: 2rem; 
}

/* .imgandvidBody{
    justify-content: center;
} */

.links{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1rem 0;
    margin-bottom: 4rem;
}

.link{
    object-fit: cover;
    height: 5rem;
    width: 5rem;
    margin: 0 0.75rem;
    margin-top: 0rem;
}

.imgandvidSmall{
    margin-top: 4rem;
    font-size: 1.4rem;
}

.previewImg{
    width: 100%;
    height: 100%;
    background-color: rgb(30, 30, 30);
    position: fixed;
    z-index: 10000;
    display: flex;
    justify-Content: center;
    align-Items: center;
    overflow: hidden; 
    left: 0;
    top: 0;
}

.previewImage{
    width: auto;
    max-Width: 90%;
    max-Height: 90%;
}

.previewClose{
    position: absolute;
    top: 2rem;
    right: 2rem;

/*     background: rgb(40, 40, 40); */
    color: white;
    border: none;
    align-items: center;
    justify-content: center;
    /* padding: 0 1.4rem; */
    border-radius: 2rem;
    cursor: pointer;
    font-size: 1.2rem;
}

.previewIcon{
    object-fit: cover;
    width: 3rem;
    height: 3rem;
}

.previewIconClose{
    object-fit: cover;
    width: 2rem;
    height: 2rem;
}

.previewLeft{
    margin-right: 2rem;
    color: white;
    border: none;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    cursor: pointer;
    font-size: 1.2rem;
}

.previewRight{
    margin-left: 2rem;
    color: white;
    border: none;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    cursor: pointer;
    font-size: 1.2rem;
}

.mainImg{
    width: 100%;
    display: block;
    cursor: pointer;
    padding: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin: 0 5px;
}

.ImagesMobile{
    display: none;
}

.ImagesDesktop{
    display: block;
}


@media screen and (max-width:745px) {
    .mainImg{
        width: 90vw;
        margin: auto;
    }

    .ImagesMobile{
        display: block;
    }

    .ImagesDesktop{
        display: none;
    }
}

@media screen and (max-width:1200px) {
    .previewImage{
        width: auto;
        max-Width: 80%;
        max-Height: 80%;
    }
}

@media screen and (max-width:1450px) {
    .previewLeft{
        margin-right: 0.5rem;
        color: white;
        border: none;
        align-items: center;
        justify-content: center;
        border-radius: 2rem;
        cursor: pointer;
        font-size: 1.2rem;
    }
    
    .previewRight{
        margin-left: 0.5rem;
        color: white;
        border: none;
        align-items: center;
        justify-content: center;
        border-radius: 2rem;
        cursor: pointer;
        font-size: 1.2rem;
    }

    .previewClose{
        top: 2rem;
        right: 2rem;
    }
}