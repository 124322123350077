#tauernsound{
    padding-top: 5rem;
    min-height: calc(100vh - 15rem);
    height: auto;
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    /* text-align: center;
    align-items: center;
    justify-content: center; */
    color: rgb(40, 40, 40);

}

.tauernsoundTitle{
    margin: 0 auto;
    text-align: center;
    font-family: "Allan", serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1px;
    color: rgb(16, 112, 190);
    /* color: rgb(16, 112, 190); */
    font-size: 4rem;
    margin-bottom: 2rem;
}

/* .tauernsoundP{
    text-align: left;
    width: 20rem;
    font-size: 1.5rem;
    margin: 0 auto;
    /* display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    justify-content: center;
} */

.tauernsoundImg{
    float: right;
    object-fit: cover;
    height: 30rem;
    margin-left: 5rem;
    align-self: center;
    border-radius: 1rem;
    position: relative;
    z-index:-5;
}

.tauernsoundBody{
    display: flex;
    align-items: center;
}

.tauernsoundP{
    text-align: justify;
    font-size: 1.3rem;
    margin-bottom: 2rem;
}

.tauernsoundBtn{
    background: rgb(30, 30, 30);
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1.5rem;
    border-radius: 2rem;
    cursor: pointer;
    font-size: 1.5rem;
}


@media screen and (max-width:1200px) {
    
    .tauernsoundBody{
        display: block;
        width: 90vw;
        margin: auto;
        align-self: center;
        justify-self: center;
    }

    .tauernsoundImg{
        float: none;
        margin: 0 auto;
        padding: 0;  
        display: block;
    }

    .tauernsoundImage{
        margin: 0 auto;
        margin-top: 2rem;
        align-items: center;
        justify-content: center;
    }

    #tauernsound{
        height: auto;
        margin-bottom: 3rem;
    }

    .tauernsoundBtn{
        display: block;
        margin: 0 auto;
    }

}