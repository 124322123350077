#contactPage{
    /* min-height: calc(100vh - 4rem); */
    width: 100vw;
    max-width: 60rem;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
    margin-top: 5rem;
}

.contactPageTitle{
    font-family: "Allan", serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1px;
    color: rgb(16, 112, 190);
    font-size: 4rem;
    margin-bottom: 1rem;
}

.clientDesc{
    font-weight: 300;
    font-size: 1rem;
}

.clientImgs{
    margin: 1rem 0;
    display: flex;
    flex-wrap: wrap;
}

.clientImg{
    object-fit: cover;
    width: 23%;
    min-width: 9rem;
    margin: auto;
    padding: 0.25rem;
}

#clients{
    padding: 2rem;
}

.contactDesc{
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: 300;
    color: rgb(40, 40, 40);
}

.contactphone{
    font-size: 1.2rem;
    font-weight: 300;
    color: rgb(40, 40, 40);
}

.contactForm{
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 60rem;
}

.name, .email, .msg, .tel, .datum, .veranstalter{
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    background: rgb(40, 40, 40);
}

.veranstaltung, .anzahl{
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    margin-left: 0rem;
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    background: rgb(40, 40, 40);
}

.ort, .spieltauer{
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    margin-right: 0rem;
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    background: rgb(40, 40, 40);
}

.twoinone{
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 40rem;
}

.submitBtn{
    background: rgb(40, 40, 40);
    color: white;
    font-size: 1.2rem;
    border: none;
    border-radius: 0.5rem;
    margin: 2rem;
    padding: 0.75rem 3.5rem;
    cursor: pointer;
}

.links{
    display: flex;
    flex-wrap: wrap;
}

.link{
    object-fit: cover;
    height: 5rem;
    width: 5rem;
    margin: 0 0.75rem;
    margin-top: 1rem;
}

.tauernsoundLink{
    margin-top: 2rem;
    object-fit: cover;
    height: 3rem;
   /*  margin-bottom: 2rem; */
}


@media screen and (max-width:840px) {
    .tauernsoundLink{
        object-fit: cover;
        height: 2rem;
    }

    .contactDesc{
        font-size: 1.1rem;
    }

    .contactphone{
        font-size: 1.1rem;
    }
    
}

@media screen and (max-width:500px) {
    .twoinone{
        display: block;
        /* flex-direction: row; */
        width: 100%;
        max-width: 40rem;
    }

    .veranstaltung, .datum, .ort, .spieltauer{
        font-size: medium;
        width: 100%;
        max-width: 40rem;
        margin: 0rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        padding: 0.5rem 1rem;
        color: white;
        border: none;
        border-radius: 0.5rem;
        background: rgb(40, 40, 40);
    }
}
