#videos{
    padding-top: 5rem;
    height: auto;
    width: 100vw;
    max-width: 100rem;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    justify-content: center; 
    color: rgb(40, 40, 40);

}

.videosTitle{
    margin: 0 auto;
    text-align: center;
    font-family: "Allan", serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1px;
    color: rgb(16, 112, 190);
    /* color: rgb(16, 112, 190); */
    font-size: 4rem;
    margin-bottom: 2rem; 
}

.videosBody{
    justify-content: center;
    align-items: center;
    margin: auto;
    /* width: 100%; */
    text-align: center;
    gap: 2rem;
} 

.videoslinks{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1rem 0;
    margin-bottom: 4rem;
    
}

.link{
    object-fit: cover;
    height: 5rem;
    width: 5rem;
    margin: 0 0.75rem;
    margin-top: 0rem;
}

.videosSmall{
    margin-top: 4rem;
    font-size: 1.4rem;
}

.video-responsive{
    overflow: hidden;
    padding-bottom: 56.25%; 
    position: relative;
    height: 0;
    margin-bottom: 2rem;
}
  
.video-responsive iframe{
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.ytemb{
    margin-bottom: 2rem;
}


@media screen and (max-width:745px) {
    
}

@media screen and (max-width:1200px) {
    
    .video-responsive{
        width: 100%;
        margin: auto;
    }

}

@media screen and (max-width:1600px) {
    .videosBody{
        width: 90vw;
    } 
}