#aboutus{
    width: 100vw;
    max-width: 100rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    padding-bottom: 0rem;
    align-items: center;
    justify-content: center;
    display: flexbox;
    height: auto;
    align-items: center;
    margin-top: -2rem;
}

.nextto{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.img2{
   /*  position: relative; */
    right: 0;
    z-index: -1;
    object-fit: cover;
    height: 80vh;
    margin: 0 auto;
}

.aboutusContent{
    /* height: 200vh;
    width: 100vw; */
    padding: 2rem;
    padding-top: 0rem;
    font-size: 1.4rem;
    font-weight: 300;
    
}

.aboutusText{
    color: rgb(30, 30, 30);
    font-size: 1.4rem;
}

.aboutusName{
    color: rgb(16, 112, 190);
    font-weight: 700;
    font-size: 1.4rem;
}

.aboutusPara{
    font-size: medium;
    font-weight: 300;
    letter-spacing: 1px;
    color: rgb(30, 30, 30);
    font-size: 1.4rem;
    text-overflow: ellipsis;
    overflow: visible;
    word-wrap: break-word;
}

.btn{
    background: white;
    margin: 1rem 0;
    margin-top: 0rem;
    padding: 0.75rem 1.5rem;
    padding-top: 0rem;
    border: none;
    border-radius: 2rem;
}

.btnImg{
    object-fit: cover;
    margin: 0 0.25rem;
    height: 1rem;
}

.worksImgs{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    max-width: 100rem;
    padding-top: 5rem;

    flex-shrink: 0;
flex-flow: row wrap;
}

.PeopleImg{
    position: relative;
    object-fit: cover;
    height: 40rem;
}

.wrapper{
    object-fit: cover;
    height: 40rem;
    margin: 1rem;
    position: relative;
    text-align: center;
    transition: 0.6s;
}

.content{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    border-radius: 2rem;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    display: flexbox;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.6s;
}
.content:hover{
    opacity: 1;
}

.content > *{
    transform: translateY(25px);
    transition: 0.6s;
}

.content:hover > *{
    transform: translateY(0px);
}

.PeopleName{
    padding-top: 32rem;
    font-size: medium;
    font-weight: 700;
    letter-spacing: 1px;
    color: white;
    font-size: 1.8rem;
    text-overflow: ellipsis;
    overflow: visible;
    word-wrap: break-word;
    
}

.PeopleInfo{
    font-size: medium;
    font-weight: 400;
    letter-spacing: 1px;
    color: white;
    font-size: 1.2rem;
    text-overflow: ellipsis;
    overflow: visible;
    word-wrap: break-word;
}

.tauernsoundLink{
    margin-top: 5rem;
    object-fit: cover;
    height: 3rem;
   /*  margin-bottom: 2rem; */
}

.tauernsoundBtn1{
    background: rgb(30, 30, 30);
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1.5rem;
    border-radius: 2rem;
    cursor: pointer;
    font-size: 1.5rem;
    margin: 0 auto;
    margin-top: 3rem;
}


@media screen and (max-width:1200px) {

    .nextto{
        display: block;
    }

    .aboutusPara{
        float: left;
        font-size: 1.3rem;
        width: 80vw;
        margin: auto;
        align-self: center;
        display: block;
    }

    .img2{
        display: block;
        right: 0;
        z-index: -1;
        object-fit: cover;
        width: 80vw;
        height: auto;
        margin: 0 auto;
    }

    .aboutusContent{
        padding: 0rem;
        margin: 0 10vw;
        display: block;
        
    }

    .PeopleImg{
        position: relative;
        object-fit: cover;
        height: 30rem;
    }
    
    .wrapper{
        object-fit: cover;
        height: 30rem;
        margin: 1rem;
        position: relative;
        text-align: center;
        transition: 0.6s;
    }

    .PeopleName{
        padding-top: 22rem;
        
    }

    .tauernsoundBtn1{
        display: block;
        margin: 0 auto;
        font-size: 1rem;
        width: 90vw;
    }

    
}

@media screen and (max-width:840px) {
    

    .nextto{
        display: block;
    }

    /* .wrapper{
        object-fit: cover;
        width: 70vw;
        height: auto;
        margin: 0.5rem auto;
    }

    .PeopleImg{
        object-fit: cover;
        width: 70vw;
        height: auto;
    } */

    .wrapper{
        object-fit: cover;
        width: 40vw;
        height: auto;
    }

    .PeopleImg{
        object-fit: cover;
        width: 40vw;
        height: auto;
    }

    .PeopleName{
        padding-top: 22rem;
        font-size: 1.5rem;
        
    }
    
    .PeopleInfo{
        font-size: 1rem;
    }

    

    .aboutusPara{
        float: left;
        font-size: 1rem;
        width: 80vw;
        margin: auto;
        align-self: center;
        display: block;
    }

    .img2{
        right: 0;
      /* z-index: -1; */
        object-fit: cover;
        width: 90vw;
        height: auto;
        /* height: 45vh; */
        margin: 0 auto;
    }

    .aboutusContent{
        padding: 0rem;
        margin: 0 10vw;
        display: block;
        
    }

    .nextto{
        display: block;
    }

    .tauernsoundLink{
        object-fit: cover;
        height: 2rem;
    }
}

@media screen and (max-width:700px) {
    .PeopleName{
        padding-top: 15rem;
        font-size: 1.5rem;
        
    }
    
    .PeopleInfo{
        font-size: 1rem;
    }
}



@media screen and (max-width:550px) {
    .worksImgs{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .wrapper{
        object-fit: cover;
        width: 70vw;
        height: auto;
        margin: 0.5rem auto;
    }

    .PeopleImg{
        object-fit: cover;
        width: 70vw;
        height: auto;
    }

    .PeopleName{
        padding-top: 25rem;
        font-size: 1.5rem;
    }
    
    .PeopleInfo{
        font-size: 1rem;
    }

}


@media screen and (max-width:460px) {
    .PeopleName{
        padding-top: 20rem;
        font-size: 1.5rem;
        
    }
}

/* @media screen and (max-width:480px) {
    .bg{
        right: -20vw;
    }
} */