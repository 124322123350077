#intro{
    height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    padding-bottom: 0rem;
}

.ContainerImg{
    position: relative;
}

.bg{
    position: relative;
    top: -0rem;
    right: 0;
    z-index: -1;
    object-fit: cover;
    height: 85vh;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.mobbg{
    display: none;
}

.logo1{
    position: absolute;
    /* display: block; */
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
    height: 15rem;
    width: 30rem;
    left: 100px;
    
    
}

.introContent{
    height: 100vh;
    width: 100vw;
    padding: 2rem;
    padding-bottom: 0rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
}

.hello{
    font-size: 1.75rem;
    font-weight: 100;
}

.introName{
    color: yellow;
}

.introPara{
    font-size: medium;
    font-weight: 300;
    letter-spacing: 1px;
}

.btn{
    background: white;
    margin: 1rem 0;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 2rem;
}

.btnImg{
    object-fit: cover;
    margin: 0 0.25rem;
    height: 1rem;
}


@media screen and (max-width:800px) {
    #intro{
        height: 90vh;
        align-items: center;
        text-align: center;
        margin: auto;
        height: auto;
        margin-bottom: 1rem;
    }

    .bg{
        display: none;
    }

    .mobbg{
        display: inline;
        /* height: 50vh; */
        margin: 0 auto;
        text-align: center;
        /* margin-left: -3.8rem; */
        margin-top: 2rem;
        width: 100vw;
    }

    .introContent{
        font-size: 10vw;
    }

    .hello{
        font-size: 4.5vw;
    }

    .introContent{
        height: 30vh;
    }


}

@media screen and (max-width:650px) {
    #intro{
        height: auto;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width:500px) {
    #intro{
        height: auto;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width:450px) {
    #intro{
        height: auto;
        margin-bottom: 1rem;
    }
}

