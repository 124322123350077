#impressum{
    padding-top: 5rem;
    height: calc(100vh - 15rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: rgb(40, 40, 40);

}

.impressumTitle{
    font-family: "Allan", serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1px;
    color: rgb(16, 112, 190);
    /* color: rgb(16, 112, 190); */
    font-size: 4rem;
    margin-bottom: 2rem;
}

.impressumTexte{
    text-align: left;
    width: 26rem;
    font-size: 1.6rem;
    align-self: center;
    margin: 0 auto;
    /* display: flex;
    flex-wrap: wrap; */
    text-align: center;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width:1300px) {
    #impressum{
        height: auto;
        margin-bottom: 3rem;
    }
}

@media screen and (max-width:500px) {
    .impressumTexte{
        width: 22rem;
        font-size: 1.4rem;
        
    }
}