#events{
    width: 100vw;
    max-width: 100rem;
    height: auto;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    color: rgb(30, 30, 30);
    margin-top: 3rem;
    text-align: center;
}

.eventsTable{
    margin: 0rem auto;
    font-size: 1.5rem;
}

table, th, td{
    border-bottom: 3px solid rgb(16, 112, 190);
    border-collapse: collapse;
}

.eventDate{
    padding-right: 10rem;
    padding-top: 2rem;
    font-size: 1.6rem;
    font-weight: 1000;
}

.eventTime{
    padding-right: 10rem;
    padding-top: 2rem;
    font-size: 1.3rem;
    width: 17rem;
}

.eventName{
    padding-right: 10rem;
    padding-top: 2rem;
    font-size: 1.6rem;
    font-weight: 1000;
}

.eventLocation{
    padding-top: 2rem;
    font-size: 1.3rem;
}

.eventsPageTitle{
    font-family: "Allan", serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1px;
    color: rgb(16, 112, 190);
    font-size: 4rem;
    margin-bottom: 1rem;
}

.mobEventsTable{
    display: none;
}


@media screen and (max-width:1200px) {
    .eventDate{
        padding-right: 3rem;
    }
    
    .eventTime{
        padding-right: 3rem;
    }
    
    .eventName{
        padding-right: 3rem;
    }
}

@media screen and (max-width: 1150px) {
    .eventTime{
        width: auto;
    }
}

@media screen and (max-width:880px) {
    .eventDate{
        padding-right: 1rem;
        font-size: 1.2rem;
    }
    
    .eventTime{
        padding-right: 1rem;
        font-size: 1rem;
    }
    
    .eventName{
        padding-right: 1rem;
        font-size: 1.2rem;
    }

    .eventLocation{
        font-size: 1rem;
    }
}

@media screen and (max-width:720px) {

    #events{
        align-items: center;
    }

    table, th, td{
        border-bottom: none;
        border-collapse: collapse;
    }

    .eventDate{
        padding-right: 2rem;
    }

    .mobtr1{
        border-bottom: 3px solid rgb(16, 112, 190);
        /* border-bottom: 3px solid rgb(30, 30, 30); */
    }

    /* .mobtr2{
        padding-bottom: 2rem;
    } */
    
    /* .eventTime{
        padding-right: 2rem;
    } */
    
    .eventName{
        padding-right: 2rem;
    }

    .eventsTable{
        display: none;
    }

    .mobEventsTable{
        display: inline;
        margin: 0rem auto;
        width: 90vw;
    }

    .mobEventDate{
        padding-top: 2.5rem;
        font-size: 1.3rem;
        font-weight: 1000;
    }
    
    .mobEventTime{
        padding-top: 2.5rem;
        font-size: 1rem;
    }
    
    .mobEventName{
        padding-top: 0.5rem;
        font-size: 1.3rem;
        font-weight: 1000;
    }
    
    .mobEventLocation{
        padding-top: 0.5rem;
        font-size: 1rem;
    }

    /* .eventsPageTitle{
        color: rgb(30, 30, 30);
    } */

    
}