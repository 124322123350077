#datenschutz{
    padding-top: 5rem;
    /* height: calc(100vh - 15rem); */
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    /* text-align: center;
    align-items: center;
    justify-content: center; */
    color: rgb(40, 40, 40);

}

.datenschutzTitle{
    margin: 0 auto;
    text-align: center;
    font-family: "Allan", serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1px;
    color: rgb(16, 112, 190);
    /* color: rgb(16, 112, 190); */
    font-size: 4rem;
    margin-bottom: 2rem;
}

/* .tauernsoundP{
    text-align: left;
    width: 20rem;
    font-size: 1.5rem;
    margin: 0 auto;
    /* display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    justify-content: center;
} */

.datenschutzImg{
    float: right;
    object-fit: cover;
    height: 30rem;
    margin-left: 5rem;
    align-self: center;
    border-radius: 1rem;
}

.datenschutzBody{
    display: flex;
    align-items: center;
}

.datenschutzP{
    text-align: justify;
    font-size: 1.3rem;
    margin-bottom: 2rem;
}

@media screen and (max-width:1250px) {

    .datenschutzBody{
        display: block;
        width: 90vw;
        margin: auto;
        align-self: center;
        justify-self: center;
    }

}