#downloads{
    padding-top: 5rem;
    height: calc(100vh - 15rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.downloadsTitle{
    font-family: "Allan", serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1px;
    color: rgb(16, 112, 190);
    /* color: rgb(16, 112, 190); */
    font-size: 4rem;
    margin-bottom: 2rem;
}

.a{
    margin: 0;
}

.downloadbtns{
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.downloadbtn{
    background: rgb(30, 30, 30);
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    
    border-radius: 2rem;
    cursor: pointer;
    font-size: 1.2rem;

    object-fit: cover;
    width: 23%;
    min-width: 5rem;
    margin: auto;
    margin: 0 3rem;
}

.downloadimg{
    object-fit: cover;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0.5rem;
    margin-left: 0rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
}


@media screen and (max-width:720px) {

    .downloadbtns{
        margin: 0 auto;
        display: inline-block;
        flex-wrap: none;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .downloadbtn{
        width: 70vw;
        margin: 0;
        margin-bottom: 2rem;
        display: flex;
    }

}