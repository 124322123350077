.footer{
    width: 100vw;
    height: 8rem;
    /* background: rgb(40, 40, 40); */
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-size: small;
    color: rgb(40, 40, 40);
    border-top: 5px solid rgb(30, 30, 30);
    position: relative;
    z-index:5;
}

.footermenu{
    margin-left: 2rem;
}

.footerlink{
    margin-right: 1rem;
    color: rgb(40, 40, 40);
    font-size: small;
    border-bottom: 2px solid rgb(40, 40, 40);
}

.sponsoren1{
    margin-right: 6rem;
}

.sponsoren2{
    margin-left: 6rem;
}

.sponsor{
    margin: 0 1rem;
    object-fit: cover;
    height: 7rem;
    /* width: 10rem; */
    /* width: 10vw; */
}

.footertext{
    font-size: 1rem;
}

.mobsponsoren{
    display: none;
}

@media screen and (max-width:1600px) {
    .sponsoren1{
        margin-right: 1rem;
    }
    
    .sponsoren2{
        margin-left: 1rem;
    }

    .sponsor{
        height: 5rem;
    }

}


@media screen and (max-width:1300px) {
    .footer{
        display: block;
    }

    .sponsoren1{
        display: none;
        margin-right: 0rem;
    }

    .sponsoren2{
        display: none;
        margin-left: 0rem;
    }

    .mobsponsoren{
        display: block;
        margin-right: 0.1rem;
        margin-left: 0.1rem;
        margin: 0 auto;
        text-align: center;
        margin-top: 1rem;
    }

    .sponsor{
        margin: 0;
        margin-bottom: 1rem;
        height: 7rem;
    }

    .footertext{
        margin: 0 5vw;
        margin-top: 2rem;
        text-align: center;
    }

    .footermenu{
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    
}