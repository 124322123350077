.navbarLogo{
    /* background: rgb(30, 30, 30); */
    background: white;
    height: 7rem;
    width: 100vw;
    /* max-width: 75rem; */
    margin: 0 auto;
    padding: 1rem 2rem;
    padding-bottom: 0rem;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 3;
    
}

.logo{
    margin-top: 1rem;
    object-fit: cover;
    height: 5rem;
    width: 10rem;
    float: left;
}

.desktopMenuListItem{
    margin: 1rem;
    cursor: pointer;
    color: rgb(30, 30, 30);
}

.desktopMenuListItem:hover{
    color: rgb(16, 112, 190);
    padding-bottom: 0.5rem;
    border-bottom: 3px solid rgb(16, 112, 190);
}

.desktopMenuBtn{
    background: rgb(30, 30, 30);
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    border-radius: 2rem;
    cursor: pointer;
}

.desktopMenuImg{
    object-fit: cover;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0.5rem;
    margin-left: 0rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
}

.active{
    color: rgb(16, 112, 190);
    padding-bottom: 0.5rem;
    border-bottom: 3px solid rgb(16, 112, 190);
}

.mobMenu{
    display: none;
    object-fit: cover;
    height: 1.8rem;
}

.navMenu{
    position: absolute;
    top: 4rem;
    right: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    min-width: 15rem;
    background: rgb(40, 40, 40);
    border-radius: 1rem;
}

.listItem{
    color: white;
    padding: 0.5rem 3rem;
    margin: 0.25rem;
    background: rgb(30, 30, 30);
}

@media screen and (max-width:720px) {
    .mobMenu{
        display: flex;
    }

    .desktopMenu{
        display: none;
    }

    .desktopMenuBtn{
        display: none;
    }
}